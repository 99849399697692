<script setup lang="ts">
import Button from '~~/components/design-system/button.vue';
import Password from '~~/components/design-system/form/password.vue';
import { CheckCircleIcon } from '@heroicons/vue/24/outline';
import CircleIcon from '~/components/design-system/icons/CircleIcon.vue';
import { useAuthStore } from '~/stores/auth.store';
import { useSiteStore } from '~/stores/site.store';

import GenericError from '~/components/user-interface/generic-error.vue';

const formLoading: globalThis.Ref<boolean> = ref(false);
const { $authService } = useServices();
const { $t } = useNuxtApp();
const auth = useAuthStore();
const site = useSiteStore();
const form = ref();

const countryCode: string = site.region;
const errorCode: globalThis.Ref<string> = ref('');

const model = ref({
  password: '',
  password_confirmation: '',
});

const navigateToSuccess = () => {
  // navigate to reset success
  auth.toggleAccountModal(false);
  site.activateModal('passwordResetStepSuccess');
};

// Submit new password
const confirmPasswordChange = () => {
  errorCode.value = null;
  const { password_confirmation, ...otherProps } = model.value;

  const data = { confirmPassword: password_confirmation, ...otherProps };

  formLoading.value = true;

  const accountId: string = auth.user_id;

  $authService
    .passwordResetLoggedIn({
      accountId,
      countryCode,
      ...data,
    })
    .then((data) => {
      if (data?.isSuccessful) {
        // redirect to otp success
        navigateToSuccess();
      } else {
        errorCode.value = data?.error?.code ?? 'general';
      }
    })
    .finally(() => {
      formLoading.value = false;
    });
};
</script>

<template>
  <div class="sm:mt-4 mx-auto sm:max-w-[428px] px-4 bg-layer-1 sm:rounded-lg">
    <p class="py-2 sm:py-3 font-bold">
      {{ $t('update-password') }}
    </p>

    <p class="mb-0 text-sm">{{ $t('enter-new-password') }}</p>

    <div>
      <Vueform
        ref="form"
        :float-placeholders="true"
        :endpoint="false"
        class="mt-1"
        :model-value="model"
        sync
      >
        <template #empty>
          <VueformPasswordElement
            :key="'password'"
            :name="'password'"
            :rules="[
              'required',
              'confirmed',
              'regex:/^(?!.*\\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*\\W)(?:.{8,20})$/',
            ]"
            :messages="{ regex: $t('formErrors.password') }"
            :display-feedback="true"
            :debounce="500"
            class="mb-3"
          />
          <VueformPasswordElement
            :key="'password_conf'"
            :name="'password_confirmation'"
            labelValue="confirm-password"
            placeholderValue="placeholder.confirm-password"
            :display-feedback="false"
            rules="required"
          />
        </template>
      </Vueform>

      <!-- Passwords must match -->
      <div
        class="field col-12 mb-0 py-0"
        v-if="form?.validated && !form?.invalid"
      >
        <div class="my-1 flex flex-row">
          <CheckCircleIcon
            class="dark:text-primary-blue-500 text-primary-blue-400 icon-success--border w-4 mr-1.5"
          />
          <span class="text-xs font-light text-base-priority">
            {{ $t('Passwords-must-match-and-be-valid') }}
          </span>
        </div>
      </div>

      <!-- Error messaging -->
      <div class="field-col-12 mb-0 pt-0 flex justify-center">
        <GenericError
          v-if="errorCode"
          class="mt-3 max-w-72 mx-auto"
          state="danger"
        >
          {{ $t(`jpc-update-password-error-` + errorCode) }}
        </GenericError>
      </div>
    </div>

    <!-- Buttons -->
    <div class="field col-12 mb-0 text-sm flex flex-row justify-center p-2">
      <Button
        class="m-2 uppercase w-full justify-center text-sm py-3"
        :disabled="!(form?.validated && !form?.invalid)"
        @click="confirmPasswordChange()"
      >
        {{ $t('update-password') }}
      </Button>
    </div>
    <LazyUserInterfaceGenericLoader v-if="formLoading" container />
  </div>
</template>
